import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import SVCard from "./SVCard";
import SvFilterTemporadas from "./SvFilterTemporadas";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
  where,
} from "firebase/firestore/lite";

function SVCards(props) {
  const [allShows, setAllShows] = useState();
  const [shows, setShows] = useState();
  const [filter, setFilter] = useState(props.filter);

  useEffect(() => {
    const db = getFirestore();
    const queryShows = query(
      collection(db, "Shows"),
      where("type", "in", filter.type),
      where("isActivated", "==", true),
      orderBy("premiereDate", "desc")
    );
    getDocs(queryShows).then((res) => {
      const wShows = [];
      res.forEach((doc) => {
        wShows.push({ ...doc.data(), id: doc.id });
      });
      setAllShows(wShows);
      setShows(
        wShows.filter(
          (show) =>
            (show.season === filter.season || filter.season === "") &&
            toNormalForm(show.title.toLowerCase()).includes(
              toNormalForm(filter.title.toLowerCase())
            )
        )
      );
    });
  }, [filter]);

  const handleFilter = (event) => {
    setFilter({ ...props.filter, ...event });
    props.saveFilter({ ...props.filter, ...event });
    setShows(
      allShows.filter(
        (show) =>
          (show.season === event.season || event.season === "") &&
          toNormalForm(show.title.toLowerCase()).includes(
            toNormalForm(event.title.toLowerCase())
          )
      )
    );
  };

  function toNormalForm(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  return (
    <>
      {
        <SvFilterTemporadas
          title={props.title}
          filter={filter}
          setFilter={handleFilter}
        />
      }
      <hr />
      {shows ? (
        <Row xs={1} md={3}>
          {shows.map((show, idx) => (
            <SVCard key={idx} show={show} selectShow={props.selectShow} />
          ))}
        </Row>
      ) : (
        ""
      )}
    </>
  );
}

export default SVCards;
